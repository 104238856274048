div.mobile-footer-container2 {
  display: block;
}
div.mobile-footer-container2 > div {
  color: #9B9B9B;
  padding-block: 0.5rem;
  margin-inline: .75rem;
  position: relative;
}
div.mobile-footer-container2 > div > p {
  color: #9B9B9B;
  font-family: better-yesterday;
  font-size: var(--font-16);
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
div.footer-div-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
}
div.footer-div-4 {
  font-size: var(--font-14);
}
.footer-img1{
  width: 100%;
  height: .875rem;
  background-image: url("../assets/Homepage/Footer\ Stripes_Biyi\ Aroloye.png");
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px){
  
  div.mobile-footer-container2 {
    display: block;
  }
div.mobile-footer-container2 > div {
    color: #9B9B9B;
    padding-block: 0.75rem;
    margin-inline: 3rem;
    position: relative;
}
div.mobile-footer-container2 > div > p {
    color: #9B9B9B;
    font-family: better-yesterday;
    font-size: var(--font-20);
    font-weight: 400;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
div.footer-div-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
    text-align: center;
  }
  div.footer-div-4 {
    font-size: var(--font-14);
  }
  .footer-img1{
    width: 100%;
    height: 2.0rem;
    background-image: url("../assets/Homepage/Footer\ Stripes_Biyi\ Aroloye.png");
    background-position: center;
    background-repeat: no-repeat;
}
}
       


@media screen and (min-width: 1024px) {
    div.mobile-footer-container2 {
        display: block;
      }
    div.mobile-footer-container2 > div {
        color: #9B9B9B;
        padding-block: 1rem;
        margin-inline: 3.375rem;
    }
    div.mobile-footer-container2 > div > p {
        color: #9B9B9B;
        font-family: better-yesterday;
        font-size: var(--font-24);
        font-weight: 400;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    div.footer-div-4 {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 0.5rem;
        text-align: center;
      }
      div.footer-div-4 {
        font-size: var(--font-16);
      }
      .footer-img1{
        width: 100%;
        height: 2.5rem;
        background-image: url("../assets/Homepage/Footer\ Stripes_Biyi\ Aroloye.png");
        background-position: center;
        background-repeat: no-repeat;
    }       
}

.lets-talk-copyright {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  text-align: center;
  color: grey;
  position: absolute;
  top: 95%;
  right: 0;
  left: 50%;
  transform: translateY(0%) translateX(-50%);
}