nav.desktop-nav{
    display: none;
}
nav.mobile-nav{
    width: 100vw;
    position: fixed;
    height: 6.875rem;
    padding-block-start: 3.375rem;
    padding-inline: 3.0rem;
    margin-block-end: 3.25rem;
    z-index: 9;
    transform: translateY(0%);
    transition: all ease 0.5s 0.5s;
}
nav.mobile-nav.disappear{
    transform: translateY(-100%);
}

div.mobile-nav-links{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: black;
    align-items: center;
    padding-block: 4px;
}
div.mobile-link{
    width: 3rem;
    height: 3rem;
    /* position: absolute;
    left: 90%;
    transform: translateX(-50%) translateY(-70%); */
}
div.mobile-link > img{
    height: 100%;
    width: 100%;
    cursor: pointer;
}
div.mobile-container{
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    display: grid;
    grid-template-columns: 50% 50%;
    transform: translateY(-100%);
    transition: all ease 0.5s;
    /* grid-template-rows: 33.33% 33.33%, 33.33%; */
}
div.mobile-container.open{
    transform: translateY(0%);
}
div.mobile-container > div{
    width: 100%;
    height: 33.3vh;
    /* height:100%; */
}
div.mobile-container > div.meet, div.mobile-container > div.insights,
div.mobile-container > div.talk{
    background-color: var(--primary-color-1);
    color: var(--primary-color-2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
div.mobile-container > div > svg, div.mobile-container > div > p{
    cursor: pointer;
}
div.mobile-container > div.talk  p,div.mobile-container > div.cancel > div{
    width: 89px;
    height: 89px;
    border-radius: 50%;
    border: 1px solid var(--primary-color-2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}
div.mobile-container > div.cancel > div{
    border: 1px solid #E00F0F;
}
div.mobile-container > div.cancel > div::after{
    content: '';
    width: 0px;
    height: 0px;
    position:absolute;
    background-color: #E00F0F;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    transition: all ease 0.5s;
}
div.mobile-container > div.cancel > div:hover::after{
    width: 70px;
    height: 70px;
}
div.mobile-container > div.portfolio,div.mobile-container > div.products,
div.mobile-container > div.cancel{
    background-color: var(--primary-color-2);
    color: var(--primary-color-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width:768px) {
    nav.desktop-nav{
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 9;
        transform: translateY(0%);
        transition: all ease 0.5s 0.5s;
    }
    nav.desktop-nav.disappear{
        transform: translateY(-100%);
    }
    nav.mobile-nav{
        display: none;
    }
    div.navbar-links{
        display: flex;
        /* gap: 2.5625rem; */
        height: 5rem;
        justify-content: space-around;
        align-items: center;
        margin-block-start: 1.875rem;
        margin-block-end: 5.625rem;
        max-width: 30rem;
        width: 80%;
        border-radius: .75rem;
        background: rgba(24, 24, 24, 0.70);
        backdrop-filter: blur(.25rem);
    }
    div.navbar-links > div.links{
        height: 4.5rem;
        width: 4.5rem;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        /* transform: translateY(-150%); */
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
        cursor: pointer;
    }

    div.navbar-links > div.links:nth-of-type(3){
        border: none;
    }
    div.navbar-links > div.links:not(:nth-of-type(3))::after{
        content: '';
        height: 3.75rem;
        width: 3.75rem;
        line-height: 12px;
        overflow: hidden;
        background-color: var(--primary-color-2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.5s;
    }
    div.navbar-links > div.links:nth-of-type(1):hover::after{
        content: 'Meet Biyi';
        font-size: var(--font-14);
        text-align: center;
    }
    div.navbar-links > div.links:nth-of-type(2):hover::after{
        content: 'Portfolio';
        font-size: var(--font-12);
        text-align: center;
    }
    div.navbar-links > div.links:nth-of-type(4):hover::after{
        content: 'Product';
        font-size: var(--font-12);
        text-align: center;
    }
    div.navbar-links > div.links:nth-of-type(5):hover::after{
        content: 'Light Pages';
        font-size: var(--font-12);
        text-align: center;
    }

    .go > #go::after {
        background-color: red;
    }

    div.navbar-links > div.links:not(:nth-of-type(3)):hover::after{
        /* height: 3.75rem;
        width: 3.75rem; */
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
    div.links  img{
        cursor: pointer
    }
    /* div.links  img{
        height: 3.0rem;
        width: 2.5rem;
        cursor: pointer;
    } */
    div.links:nth-of-type(3)  img{
        width: 100%;
        transform: scale(0.75);
    }
    div.chat{
        position: absolute;
        top: 1.875rem;
        right: 3.0rem;
        height: 4.5rem;
        width: 4.5rem;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size:var(--font-14);
        line-height: 12px;
        border-radius: 50%;
        border: 1px solid #fff;
        cursor: pointer;
    }
    #content::after{
        content: "\a";
        white-space: pre;
    }
    
    div.chat::after{
        content: "Let's Talk";
        height: 3.5rem;
        width: 3.5rem;
        background: var(--primary-color-2);
        color: var(--primary-color-1);
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform: translateX(-50%) translateY(-50%) scale(0);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-14);
        transition: all ease 0.5s;
    }
    div.chat:hover::after{
        /* font-size: var(--font-20); */
        text-align: center;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
}

@media screen and (min-width:1024px) {
    nav.desktop-nav{
        /* height: 5.5625rem; */
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 9;
    }
    div.navbar-links{
        display: flex;
        /* gap: 2.5625rem; */
        height: 5.5rem;
        justify-content: space-around;
        align-items: center;
        margin-block-start: 1.875rem;
        margin-block-end: 5.625rem;
        max-width: 35.875rem;
        width: 80%;
        border-radius: .75rem;
        background: rgba(24, 24, 24, 0.70);
        backdrop-filter: blur(.25rem);
    }
    div.navbar-links > div.links{
        height: 5.25rem;
        width: 5.25rem;
        display: flex;
        /* transform: translateY(-150%); */
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
    }
   

    div.navbar-links > div.links:nth-of-type(3){
        border: none;
    }
    div.navbar-links > div.links:not(:nth-of-type(3))::after{
        content: '';
        height: 4.75rem;
        width: 4.75rem;
        background-color: var(--primary-color-2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.5s;
        line-height: 16px;
    }
    div.navbar-links > div.links:nth-of-type(1):hover::after{
        content: "Meet 'Biyi";
        line-height: 1.2;
        font-size: var(--font-18);
        /* text-align: center; */
    }
    div.navbar-links > div.links:nth-of-type(2):hover::after{
        content: 'Portfolio';
        font-size: var(--font-16);
        /* text-align: center; */
    }
    div.navbar-links > div.links:nth-of-type(4):hover::after{
        content: 'Product';
        font-size: var(--font-16);
        text-align: center;
    }
    div.navbar-links > div.links:nth-of-type(5):hover::after{
        content: 'Light Pages';
        font-size: var(--font-16);
        text-align: center;
    }
    div.navbar-links > div.links:not(:nth-of-type(3)):hover::after{
        /* height: 4.5rem;
        width: 4.5rem; */
        transform: translateX(-50%) translateY(-50%)  scale(1);
    }
    /* div.links:not(:nth-of-type(3))  img{
        height: 3.125rem;
        width: 1.5rem;
        cursor: pointer;
    } */
    div.links:nth-of-type(3) img{
        width: 100%;
        transform: scale(0.75);
    }
    div.chat{
        position: absolute;
        top: 1.875rem;
        line-height: 16px;
        right: 3.75rem;
        height: 5.25rem;
        width: 5.25rem;
        color: #fff;
        /* text-align: center; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-18);
        font-weight: 600;
        border-radius: 50%;
        border: 1px solid #fff;
    }  
    div.chat::after{
        content: "";
        height: 4.75rem;
        width: 4.75rem;
        background: var(--primary-color-2);
        color: var(--primary-color-1);
        position: absolute;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-18);
        transition: all ease 0.5s;
    }
    div.chat:hover::after{
        content: "Let's Talk";
        /* font-size: var(--font-20); */
        text-align: center;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
}
